import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    subtitle1: {
      color: "#0C0304",
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#3BFFF8",
    },
    secondary: {
      main: "#6F3DC6",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#0C0304",
      secondary: "#6F3DC6",
      disabled: "#CCC",
      hint: "#6F3DC6",
    },
  },
  shape: {
    borderRadius: 6,
  },
})

export default theme
