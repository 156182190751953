import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import Typography from "@material-ui/core/Typography"
import Image from "./image"
import { Link } from "gatsby"


interface IProps {
  service: Service
}

interface Service {
  title: string
  image: string
  url: string
}

const ServiceCard = ({ service, ...props }: IProps) => {
  return (



    <Card raised={false}	>
       <CardActionArea href={service.url}>
      <CardContent style={{ textAlign: "center"}}>

     
        <div style={{ width: 90, padding: 10, margin: "auto" }}>
          <Image alt={service.title} filename={service.image} />
        </div>

        <Typography variant="h6" component="h3" color="inherit">
          {service.title}
        </Typography>


      </CardContent>

 



      </CardActionArea>


    </Card>

  )
}
export default ServiceCard
