import React from "react"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Image from "./image"

interface IProps {
  article: Article
}

interface Article {
  title: string
  image: string
  description: string
  url: string
}

const ArticleCard = ({ article, ...props }: IProps) => {
  return (
    <Card>

      <CardContent style={{ textAlign: "center", backgroundColor: "#6F3DC6", color: "#fff" }}>
      <Typography variant="h3" component="p" color="primary">
          {article.number}
        </Typography>
        <Typography variant="h6" component="h4" color="primary">
          {article.title}
        </Typography>

      </CardContent>
    </Card>
  )
}

export default ArticleCard
