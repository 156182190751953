import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Image from "./image"
import Bar from "./bar"
import Button from "@material-ui/core/Button"

const AboutPanel = (props: { title: string, description: string, image: string }) => {
  return (
    <Grid container spacing={3} style={{ minHeight: 500, marginTop: 40, marginBottom: 40 }} component="section">

      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <Typography variant="h4" component="h2" color="inherit">
          {props.title}
        </Typography>
        <Bar />
        <Typography
          variant="subtitle1"
          component="p"
          style={{ marginBottom: 30, marginTop: 20 }}
        >

          {props.description}
        </Typography>
        <Button variant="contained" color="primary" size="medium" onClick={() => window.Calendly.initPopupWidget({ url: 'https://calendly.com/elastic-team/intro' })}>
          Schedule a Call
        </Button>
      </Grid>
      <Grid
        item={true}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ padding: 10 }}
      >
        <div style={{ width: "100%" }}>
          <Image
            alt={props.title}
            filename={props.image}
          />
        </div>
      </Grid>
    </Grid>
  )
}
export default AboutPanel
