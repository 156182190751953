import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ArticleCard from "./article-card"
import Bar from "./bar"

const Articles = () => {
  const articles = [
    {
      title: "IT Proffesionals Around the World",
      description:
        "In this case, the role of the health laboratory is very important to do a disease detection...",
      url: "",
      number: "300+",
    },
    {
      title: "Succesful Projects On Time and Budget",
      description:
        "Herbal medicine is very widely used at this time because of its very good for your health...",
      url: "",
      number: "200+",
    },
    {
      title: "Lower risk than traditional IT projects",
      description:
        "A healthy lifestyle should start from now and also for your skin health. There are some...",
      url: "",
      number: "70%",
    },
  ]

  return (
    <Grid container spacing={3} style={{ textAlign: "center" , marginBottom: 50, marginTop: 50 }} component="section">
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5" component="h3">Our Impact</Typography>
        <Bar />
      </Grid>
      {articles.map(article => (
        <Grid item xs={12} sm={4} key={article.title}>
          <ArticleCard article={article} />
        </Grid>
      ))}

      <Grid item xs={12} spacing={3} style={{ padding: '80px 0' }}>
        <Button  variant="contained" color="primary" size="large" onClick={() => window.Calendly.initPopupWidget({ url: 'https://calendly.com/elastic-team/intro' })}>
          Schedule a Call
        </Button>
      </Grid>
    </Grid>
  )
}
export default Articles
