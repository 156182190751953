import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Image from "./image"


const Hero = (props: { title: string , description: string, image: string}) => {
  return (
    <Grid container spacing={3} style={{ minHeight: 500, marginTop: 40 , marginBottom: 40 }} component="section">
      <Grid
        item={true}
        sm={6}
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ padding: 20}}
      >
        <div style={{ width: "100%" }}>
          <Image alt="Agile Software Development teams." filename={props.image} />
        </div>
      </Grid>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}
      >
        <Typography variant="h4" component="h1" color="inherit" style={{ marginBottom: 20 }}>
          {props.title}
        </Typography>

        <Typography variant="subtitle1" component="p" style={{ marginBottom: 30 }}>
          {props.description}
        </Typography>
        <Button variant="contained" color="primary" size="medium" onClick={() => window.Calendly.initPopupWidget({ url: 'https://calendly.com/elastic-team/intro' })}>
          Schedule a Call
        </Button>
      </Grid>


    </Grid>
  )
}
export default Hero
