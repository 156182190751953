import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import ServiceCard from "./service-card"
import Bar from "./bar"

const Services = () => {
  let services = [
    {
      id: 1,
      image: "cloud.png",
      title: "AWS & GCP Cloud Application Engineers",
      url: '/cloud-application-engineers'
    },
    {
      id: 2,
      image: "ehr.png",
      title: "HIPAA Compliant Software Development Experts",
      url: '/hipaa-compliant-app-developers'
    },
    {
      id: 3,
      image: "pci.png",
      title: "PCI Compliance Application Development Specialists",
      url: '/pci-compliance-development-specialists'
    },
    {
      id: 4,
      image: "ecommerce.png",
      title: "Headless E-commerce Software Developers",
      url: '/ecommerce-software-developers'
    },
    {
      id: 6,
      image: "block.png",
      title: "Blockchain Smart Contract, NFT Software Developers",
      url: '/blockchain-nft-developers'
    },
  ]

  return (
    <Grid
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
        marginBottom: 50,
        marginTop: 50,
      }}
      component="section"
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ marginBottom: 40, marginTop: 30 }}
      >
        <Typography variant="h5" component="h2">How We Can Help You
        </Typography>
        <Bar />
      </Grid>

      {services.map(service => (
        <Grid item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}

    </Grid>
  )
}
export default Services
